<template>
  <Subview
    :contentPadding="false"
    class="main-content settings"
    customContentPadding="px-0 pb-2"
  >
    <v-layout
      slot="subview-content"
      :class="$vuetify.breakpoint.smAndUp && 'settings-view-tab-content'"
      :style="{
        maxHeight:
          $vuetify.breakpoint.smAndUp && `calc(100vh - 176px) !important`,
      }"
    >
      <v-flex class="xs12 sm6">
        <SubscriptionForm />
      </v-flex>
      <v-flex class="xs12 sm6">
        <InvoiceDetailsForm />
      </v-flex>
    </v-layout>
  </Subview>
</template>

<script>
import Subview from "@/components/Subview";
import ProxyCRUDMixin from "@/mixins/ProxyCRUDMixin";
import { mapGetters, mapActions } from "vuex";
import _ from "lodash";

export default {
  mixins: [ProxyCRUDMixin],
  components: {
    Subview,
    InvoiceDetailsForm: () =>
      import("@/components/forms/subscriptions/InvoiceDetailsForm"),
    SubscriptionForm: () =>
      import("@/components/forms/subscriptions/SubscriptionForm"),
  },
  computed: {
    ...mapGetters("ui", ["settingsActiveTab", "accessToMedicalEmployees"]),
  },
  methods: {
    ...mapActions("ui", [
      "updateSettingsActiveTab",
      "updateAccessToMedicalEmployees",
    ]),
  },
};
</script>

<style lang="scss" scoped>
.settings-view-tab-content {
  background-color: transparent;
  overflow: auto !important;
}
</style>
